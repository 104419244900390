import { createSlice } from "@reduxjs/toolkit";
import markerIconSvg from "../../assets/img/icon/GPS_filled_selected.svg";

export const tpPositionSlice = createSlice({
    name: 'positioning',
    initialState: {
        zoom: 12,
        center: {lat: 51, lng: 9},
        waypoints: [],
        placedMarkers: [],
        recommendedDistance: 2500,
        distance: 0,
        errors: [],
        nonBlockingErrors: [],
        expectLoad: false,

        mobile_current_step: 0
    },
    reducers: {

        setDefaultOptions(state, data) {
            state.zoom = 12;
            state.center = {lat: 51, lng: 9};
            state.waypoints = [];
            state.placedMarkers = [];
            state.recommendedDistance = 2500;
            state.distance = 0;
            state.errors = [];
            state.nonBlockingErrors = [];
            state.expectLoad = false;
    
            state.mobile_current_step = 0
        },

        setZoom(state, zm) {
            state.zoom = parseInt(zm.payload);
        },
        setCenter(state, c) {
            state.center = c.payload;
        },
        setPlacedMarkers(state, m) {
            const marker = m.payload;
            const serializablePosition = {
                lat: marker.position.lat(),
                lng: marker.position.lng()
            }
            marker.position = serializablePosition;
            state.placedMarkers = m.payload;
        },
        resetPlacedMarkers(state, m) {
            state.placedMarkers = [];
        },
        setRecommendedDistance(state, d){
            state.recommendedDistance = d.payload;
        },
        addNewPlacedmarker(state, m) {
            const marker = m.payload;
            const serializablePosition = {
                lat: marker.position.lat(),
                lng: marker.position.lng()
            }
            const serializableIcon = {
                anchor: {
                    x: marker.icon.anchor.x,
                    y: marker.icon.anchor.y,
                },
                scaledSize: {
                    width: marker.icon.scaledSize.width,
                    height: marker.icon.scaledSize.height,
                },
                size: {
                    width: marker.icon.size.width,
                    height: marker.icon.size.height,
                },
                url: marker.icon.url,
            }
            marker.icon = serializableIcon;
            marker.position = serializablePosition;
            state.placedMarkers.push(marker);
        },
        removePlacedMarkerByIndex(state, markerIndex) {
            state.placedMarkers.splice(markerIndex.payload, 1);
        },
        updatePlacedMarkerState(state, updatedMarker) {
            const serializedUpdatedMarker = updatedMarker.payload;
            const serializablePosition = {
                lat: serializedUpdatedMarker.position.lat(),
                lng: serializedUpdatedMarker.position.lng()
            }
            const serializableIcon = {
                anchor: {
                    x: serializedUpdatedMarker.icon.anchor.x,
                    y: serializedUpdatedMarker.icon.anchor.y,
                },
                scaledSize: {
                    width: serializedUpdatedMarker.icon.scaledSize.width,
                    height: serializedUpdatedMarker.icon.scaledSize.height,
                },
                size: {
                    width: serializedUpdatedMarker.icon.size.width,
                    height: serializedUpdatedMarker.icon.size.height,
                },
                url: serializedUpdatedMarker.icon.url,
            }
            serializedUpdatedMarker.icon = serializableIcon;
            serializedUpdatedMarker.position = serializablePosition;
            const index = state.placedMarkers.findIndex(marker => marker.id === serializedUpdatedMarker.id)
            if(index > -1) {
                state.placedMarkers[index] = serializedUpdatedMarker;
            }
        },
        setWaypoints(state, waypoints) {
            state.waypoints = waypoints.payload;
        },
        updateWaypoint(state, w) {
            const updatedWaypoint = w.payload;
            const index = state.waypoints.findIndex(waypoint => waypoint.step === updatedWaypoint.step)
            if(index > -1) {
                state.waypoints[index] = updatedWaypoint;
            }
        },
        resetWaypoints(state, m) {
            state.waypoints = [];
        },
        setPositionErrors(state, e){
            state.errors = e.payload
        },
        addPositionError(state, e){
            const error = e.payload;
            const index = state.errors.findIndex(e => e.id == error.id);
            const errorAlreadyExists = index > -1;
            if(!errorAlreadyExists){
                state.errors.push(error);
            }
        },
        removePositionErrorById(state, i){
            const id = i.payload;
            state.errors = state.errors.filter(error => error.id != id);
        },
        addNonBlockingPositionError(state, e){
            const error = e.payload;
            const index = state.nonBlockingErrors.findIndex(e => e.id == error.id);
            const errorAlreadyExists = index > -1;
            if(!errorAlreadyExists){
                state.nonBlockingErrors.push(error);
            }
        },
        removeNonBlockingPositionErrorById(state, i){
            const id = i.payload;
            state.nonBlockingErrors = state.nonBlockingErrors.filter(error => error.id != id);
        },
        expect(state) {
            state.expectLoad = true;
        },
        unexpect(state) {
            state.expectLoad = false;
        },
        setTourstepPositions(state) {
            const icon = markerIconSvg;
            const markerIcon = {
                url: icon,
                scaledSize: new window.google.maps.Size(32, 32),
                size: new window.google.maps.Size(32, 32),
                anchor: new window.google.maps.Point(15, 32),
            };

            let avgLat = 0;
            let avgLon = 0;
            const k = state.waypoints.length - 1;
            for (let i = 0; i < state.waypoints.length; ++i) {
                let pos = state.waypoints[i].position;
                avgLat += pos.lat;
                avgLon += pos.lng;

                let markerLabel = "" + i;
                if (i == k) {
                    markerLabel = "X";
                }

                var marker = {
                    position: new window.google.maps.LatLng(pos.lat, pos.lng),
                    id: i,
                    icon: markerIcon,
                    label: {
                        color: "#2f385fff",
                        fontFamily: "DeLittleChromaticBold",
                        fontSize: "18px",
                        fontWeight: "600",
                        text: markerLabel,
                    },
                };

                tpPositionSlice.caseReducers.addNewPlacedmarker(state, {payload: marker})
            }
            avgLat /= state.waypoints.length;
            avgLon /= state.waypoints.length;
            tpPositionSlice.caseReducers.setCenter(state, {payload:{lat: avgLat, lng: avgLon}});
            state.expectLoad = false;
        },
        setMobileStep(state, index) {
            state.mobile_current_step = index.payload;
        },
        incrementMobileStep(state) {
            if (state.mobile_current_step < state.waypoints.length-1) {
                ++state.mobile_current_step;
            }
        },
        decrementMobileStep(state) {
            if (state.mobile_current_step > 0) {
                --state.mobile_current_step;
            }
        },
        setDistance(state, d) {
            state.distance = parseInt(d.payload);
        },
    }
});

export const {setDefaultOptions, setZoom, setCenter, setPlacedMarkers, addNewPlacedmarker, removePlacedMarkerByIndex, updatePlacedMarkerState, updateWaypoint, setWaypoints, setRecommendedDistance, setPositionErrors, addPositionError, removePositionErrorById, expect, unexpect, setTourstepPositions, setMobileStep, incrementMobileStep, decrementMobileStep, setDistance, resetPlacedMarkers, resetWaypoints, addNonBlockingPositionError, removeNonBlockingPositionErrorById} = tpPositionSlice.actions;

export default tpPositionSlice.reducer;