import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import background from "../assets/img/Backgrounds/Hintergrund_4.png";
import minbackground from "../assets/img/minimized/Hintergrund5_16_9.png";
import Headline from "./subcomponent/Headline";
import Section from "./Section";
import jonathan from "../assets/img/people/jonathan.jpg";
/* import linda from "../assets/img/people/linda.jpg";
import enrique from "../assets/img/people/enrique.jpg"; */
import { Container, Button, Form } from "react-bootstrap";

function ContactRedesign() {
  const { t } = useTranslation(["contact_redesign"]);

  const people = [
    {
      image: jonathan,
      name: t("project_genesis.people.jonathan.name"),
      role: t("project_genesis.people.jonathan.role"),
    },
  ];

  function getRandomPerson() {
    const randomPerson = people[Math.floor(Math.random() * people.length)];
    return randomPerson;
  }

  const phoneClick = (ev) => {
    document.location.href = "tel:" + t("project_genesis.phone_number_action");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "phoneButtonClicked",
      person: selectedPerson,
    });
  };

  const emailClick = (ev) => {
    window.location.href = `mailto:${t("project_genesis.email")}`;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "emailButtonClicked",
      person: selectedPerson,
    });
  };

  let selectedPerson = getRandomPerson() || people[0];

  return (
    <div id="a-contact" className="contact-content">
      <div className="contact-headline">{t("project_genesis.headline")}</div>

      <div className="rotated-banner">
        <div className="background-banner orangeBackground"></div>
        <div className="content">
          <div className="contact-container">
            <div className="row">
              <div className="person col">
                <div className="row">
                  <div className="col">
                    <img
                      className="personImage"
                      src={selectedPerson.image}
                      alt={selectedPerson.name}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 w-full">
                    <span className="big-text">{selectedPerson.name}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 w-full">
                    <span className="small-text">{selectedPerson.role}</span>
                  </div>
                </div>
              </div>
              <div className="contactText col">
                <div className="row">
                  <div className="row">
                    <div className="col-4 w-full">
                      <span className="big-text align-left">
                        {t("project_genesis.contact_us_big")}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 w-full">
                      <span className="small-text align-left">
                        {t("project_genesis.contact_us_small")}
                      </span>
                    </div>
                  </div>
                  <div className="row buttons">
                    <div className="col-4 w-full">
                      <Button className="mail-button" onClick={emailClick}>
                        <i className="bi bi-envelope buttonIcons" style={{marginRight: 4}}></i>
                        {t("project_genesis.by_mail")}
                      </Button>
                      <Button className="phone-button" onClick={phoneClick}>
                        <i className="bi bi-telephone buttonIcons" style={{marginRight: 4}}></i>
                        {t("project_genesis.phone_number_display")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactRedesign;
