import { useTranslation } from "react-i18next";
import poster_pirate from "../assets/img/curriculum/Poster_Piratenschatz.png";
import poster_sherlock from "../assets/img/curriculum/Poster_Sherlock.png";

function Curriculum(props) {
    const { t } = useTranslation(["curriculum"]);

    return (
        <div id="a-curriculum" className="curriculum-content">
            <div className="curriculum-headline">{t("headline")}</div>
            <p className="curriculum-subtext">{t("subtext")}</p>
            <div className="curriculum-poster">
                <img src={poster_pirate} />
                <img src={poster_sherlock} />
            </div>
        </div>
    )
}

export default Curriculum;