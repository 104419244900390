import { Container, Image as Img } from "react-bootstrap";

async function lazyLoadFunction(e, id) {
    let imageElement = document.getElementById("lazyElementOf" + id)
    if (imageElement) {
        let parent = imageElement.parentNode;
        parent.removeChild(imageElement);
        e.target.classList.remove("bg");
    }
}

function WrapperSection(props) {
    return (
        <Container fluid id={props.id} className="position-relative ps-0 pe-0" onMouseMoveCapture={props.mouseMove}>
            {props.bg ? <Img src={props.bg} className="bg" style={{minWidth: "100%"}} onLoad={(e) => lazyLoadFunction(e, props.id)}/> : null}
            <Img src={props.minbg} id={"lazyElementOf" + props.id} className="loading" loading="eager" style={{minWidth: "100%"}}/>
            <div className={'content-space' + ((props.className) ? " " + props.className : "")}>
                {props.children}
            </div>
        </Container>
    );
}

export default WrapperSection;