import React from "react";
import { useTranslation } from "react-i18next";
import bg from "../assets/img/Hintergrund11.png";
import minbackground from "../assets/img/Hintergrund2_16_9.png";
import res_bg from "../assets/img/responsive_backgrounds/Background2_Responsive.png";
import min_res_bg from "../assets/img/responsive_backgrounds/minimized/Background2_Responsive.png";
import ExploreShort from "./subcomponent/ExploreShort";
import shortterm from "../assets/img/icon/Zeitunabhängig.png";
import individuel from "../assets/img/icon/Individuell.png";
import everywhere from "../assets/img/icon/Ortsunabhängig.png";

import teamwork from "../assets/img/icon/teamwork.png";
import multimedial from "../assets/img/icon/multimedial.png";
import costEffective from "../assets/img/icon/Einzigartig.png";

import Headline from "./subcomponent/Headline";
import { Container, Image as Img } from "react-bootstrap";
import { useScreenWidth } from "../ScreenWidthContext";
import Section from "./Section";
import { isMobile, responsive } from "../App";
import SideSroller from "./subcomponent/SideScroller";

function Explore({ instant }) {
  const { t } = useTranslation(["welcome_explore"]);
  const screenWidth = useScreenWidth();

  window.addEventListener("scroll", (ev) => {
    var animDiv = document.getElementById("explore-shorts-container-anim");
    var ExploreSection = document.querySelector("#a-explore > img");
    if (
      ExploreSection !== null &&
      animDiv !== null &&
      ExploreSection.getBoundingClientRect().bottom / 1.5 - window.scrollY < 1
    ) {
      // Make subjects appear when scrolling down
      if (!animDiv.classList.contains("explore-fade-in")) {
        animDiv.classList.add("explore-fade-in");
        instant.current = true;
      }
    }
  });

  async function lazyLoadFunction(e, id) {
    let imageElement = document.getElementById("lazyElementOf" + id);
    if (imageElement) {
      let parent = imageElement.parentNode;
      parent.removeChild(imageElement);
      e.target.classList.remove("bg");
    }
  }

  return (
    <div id="a-explore" className="explore-content">
      <div className="explore-headline">
        {t("project_genesis.explore.headline")}
      </div>

      <div>
        <div className="">
          <p className="explore-text desktop text-break">
            {t("project_genesis.explore.text")}
          </p>
        </div>
        <div
          className={
            "explore-shorts-container" +
            (instant.current ? " explore-fade-instant" : "")
          }
          id="explore-shorts-container-anim"
        >
          <ExploreShort
            icon={everywhere}
            title={t("project_genesis.explore.shorts.everywhere.title")}
            description={t(
              "project_genesis.explore.shorts.everywhere.description"
            )}
          />
          <ExploreShort
            icon={shortterm}
            title={t("project_genesis.explore.shorts.shortterm.title")}
            description={t(
              "project_genesis.explore.shorts.shortterm.description"
            )}
          />
          <ExploreShort
            icon={individuel}
            title={t("project_genesis.explore.shorts.individuel.title")}
            description={t(
              "project_genesis.explore.shorts.individuel.description"
            )}
          />

          <ExploreShort
            icon={teamwork}
            title={t("project_genesis.explore.shorts.teamwork.title")}
            description={t(
              "project_genesis.explore.shorts.teamwork.description"
            )}
          />
          <ExploreShort
            icon={multimedial}
            title={t("project_genesis.explore.shorts.multimedial.title")}
            description={t(
              "project_genesis.explore.shorts.multimedial.description"
            )}
          />
          <ExploreShort
            icon={costEffective}
            title={t("project_genesis.explore.shorts.costEffective.title")}
            description={t(
              "project_genesis.explore.shorts.costEffective.description"
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Explore;
