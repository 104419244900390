import { createSlice } from "@reduxjs/toolkit";

function getConfig(conf, presets, k) {
    let value = presets[k];
    let config = conf.find(key => key.key === k);
    return config.possible_values.find(v => v.key == value);
}
const minNumberOfGroups = 3;

export const tpCategoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        digital: {},
        playerCount: 12,
        difficulty: 0,
        schoolType: {id: 3, name: "middle_school", difficulty: 0 },
        classGrade: 5,
        duration: 1,
        preparation: 1,
        theme: null,
        outdoor: {},
        hintNumber: 0,
        themes: []
    },
    reducers: {
        setDefaultOptions(state, apiData) {
            let data = apiData.payload;
            state.schoolType = {id: 3, name: "middle_school", difficulty: 0 };
            state.digital = data.download_exclusive.possible_values[1];
            state.outdoor = data.indoor.possible_values[1];
            state.playerCount = data.group_size_num * minNumberOfGroups;
            state.classGrade = data.grades.possible_values[Math.floor(data.grades.possible_values.length / 2)];
            state.duration = data.duration.possible_values[0];
            state.themes = data.topic.possible_values;
            state.preparation = data.preparation.possible_values[1];
            state.difficulty = data.difficulty.possible_values[1];
        },
        setDigital(state, isDigital) {
            state.digital = isDigital.payload;
        },
        setPlayerCount(state, pc) {
            state.playerCount = parseInt(pc.payload);
            state.password = null;
        },
        setDifficulty(state, d) {
            state.difficulty = d.payload;
        },
        setClassGrade(state, cg) {
            state.classGrade = cg.payload;
        },
        setSchoolType(state, action) {
            const { schoolType, difficultyValues } = action.payload;
            state.schoolType = schoolType;
            state.difficulty = difficultyValues.find(d => d.key === schoolType.difficulty+"");
            

        },
        setDuration(state, d) {
            state.duration = d.payload;
        },
        setPreparation(state, p) {
            state.preparation = p.payload;
        },
        setTheme(state, t) {
            let theme = t.payload;
            if (theme.coming_soon) {
                let first = state.themes.find((th) => !th.coming_soon);
                state.theme = (first) ? first : state.themes[0];
            } else {
                state.theme = theme;
            }
        },
        setOutdoor(state, isOutdoor) {
            state.outdoor = isOutdoor.payload;
        },
        setHintNumber(state, hn) {
            state.hintNumber = parseInt(hn.payload);
        },
        loadPresets(state, data) {
            const payload = data.payload;
            var conf = payload.configuration;
            var presets = payload.tour.presets;
            //console.log(conf, presets);
            state.digital = getConfig(conf, presets, "download_exclusive");
            state.outdoor = getConfig(conf, presets, "indoor");
            state.duration = getConfig(conf, presets, "duration");
            state.classGrade = getConfig(conf, presets, "grades");
            state.preparation = getConfig(conf, presets, "preparation");
            state.theme = getConfig(conf, presets, "topic");
            state.difficulty = getConfig(conf, presets, "difficulty");
            state.playerCount = Number(conf.find(key => key.key == "group_size").possible_values[0].key) * minNumberOfGroups;
        }
    }
});

export const {setDefaultOptions, setDigital, setPlayerCount, setDifficulty, setClassGrade, setSchoolType, setDuration, setPreparation, setTheme, setOutdoor, setHintNumber, loadPresets} = tpCategoriesSlice.actions;

export default tpCategoriesSlice.reducer;