import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
 //Deutsche Texte
import menu_de from './assets/locales/de/menu.json'
import welcome_explore_de from './assets/locales/de/welcome_explore.json'
import howto_de from './assets/locales/de/howto.json'
import tour_planner_de from './assets/locales/de/tour_planner.json'
import faq_de from './assets/locales/de/faq.json'
import about_de from './assets/locales/de/about.json'
import footline_de from './assets/locales/de/footline.json'
import blog_de from './assets/locales/de/blog.json'
import agb_de from './assets/locales/de/agb.json'
import dataprotection_de from './assets/locales/de/dataprotection.json'
import adventure_showcase_de from './assets/locales/de/adventure_showcase.json'
import contact_de from './assets/locales/de/contact.json'
import contact_redesign_de from './assets/locales/de/contactRedesign.json'
import imprint_de from './assets/locales/de/imprint.json'
import explenation_de from './assets/locales/de/explenation.json'
import curriculum_de from './assets/locales/de/curriculum.json';

//English texts
import menu_en from './assets/locales/en/menu.json'
import welcome_explore_en from './assets/locales/en/welcome_explore.json'
import howto_en from './assets/locales/en/howto.json'
import tour_planner_en from './assets/locales/en/tour_planner.json'
import faq_en from './assets/locales/en/faq.json'
import about_en from './assets/locales/en/about.json'
import footline_en from './assets/locales/en/footline.json'
import blog_en from './assets/locales/en/blog.json'
import agb_en from './assets/locales/en/agb.json'
import dataprotection_en from './assets/locales/en/dataprotection.json'
import adventure_showcase_en from './assets/locales/en/adventure_showcase.json'
import contact_en from './assets/locales/en/contact.json'
import contact_redesign_en from './assets/locales/en/contactRedesign.json'
import imprint_en from './assets/locales/en/imprint.json'
import explenation_en from './assets/locales/en/explenation.json'
import curriculum_en from './assets/locales/en/curriculum.json';


export const resources = {
    de : {
        menu: menu_de,
        welcome_explore: welcome_explore_de,
        howto: howto_de,
        tour_planner: tour_planner_de,
        faq: faq_de,
        about: about_de,
        footline: footline_de,
        blog: blog_de,
        agb: agb_de,
        dataprotection: dataprotection_de,
        adventure_showcase: adventure_showcase_de,
        contact: contact_de,
        contact_redesign: contact_redesign_de,
        imprint: imprint_de,
        explenation: explenation_de,
        curriculum: curriculum_de
    },
    en : {
        menu: menu_en,
        welcome_explore: welcome_explore_en,
        howto: howto_en,
        tour_planner: tour_planner_en,
        faq: faq_en,
        about: about_en,
        footline: footline_en,
        blog: blog_en,
        agb: agb_en,
        dataprotection: dataprotection_en,
        adventure_showcase: adventure_showcase_en,
        contact: contact_en,
        contact_redesign: contact_redesign_en,
        imprint: imprint_en,
        explenation: explenation_en,
        curriculum: curriculum_en
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "de",
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;