import { createSlice } from "@reduxjs/toolkit";

export const tpPriceSlice = createSlice({
    name: 'price',
    initialState: {
        pricePerPersonDigital: -1,
        pricePerPersonPhysical: -1,
    },
    reducers: {
        setPricePerPersonDigital(state, p) {
            state.pricePerPersonDigital = p.payload;
        },
        setPricePerPersonPhysical(state, p) {
            state.pricePerPersonPhysical = p.payload;
        },        
    }
});

export const {setPricePerPersonDigital, setPricePerPersonPhysical} = tpPriceSlice.actions;

export default tpPriceSlice.reducer;