import { createSlice } from "@reduxjs/toolkit";

export const tpOverviewSlice = createSlice({
    name: 'overview',
    initialState: {
        tourName: "",
        tourCode: "",
        errors: []
    },
    reducers: {
        setTourName(state, d) {
            state.tourName = d.payload;
        },
        setTourCode(state, d) {
            state.tourCode = d.payload;
        },
        setOverviewErrors(state, e){
            state.errors = e.payload
        },
        addOverviewError(state, e){
            const error = e.payload;
            const index = state.errors.findIndex(e => e.id == error.id);
            const errorAlreadyExists = index > -1;
            if(!errorAlreadyExists){
                state.errors.push(error);
            }
        },
        removeOverviewErrorById(state, i){
            const id = i.payload;
            state.errors = state.errors.filter(error => error.id != id);
        }
    }
});

export const {setTourName, setTourCode, setTourCodeErrors, addOverviewError, removeOverviewErrorById} = tpOverviewSlice.actions;

export default tpOverviewSlice.reducer;