import { createSlice } from "@reduxjs/toolkit";

export const tpReserveSlice = createSlice({
    name: 'reservation',
    initialState: {
        date: null,
        reservedUntilDate: null,
        email: "",
        emailRepeat: "",
        phoneNumber: "",
        password: "",
        errors: []
    },
    reducers: {
        setDefaultOptions(state, data) {
            state.date = null;
            state.reservedUntilDate = null;
            state.email = "";
            state.emailRepeat = "";
            state.phoneNumber = "";
            state.password= "";
            state.errors= []
        },
        setDate(state, d) {
            state.date = d.payload;
        },
        setReservedUntilDate(state, d) {
            state.reservedUntilDate = d.payload;
        },
        setEmail(state, e) {
            state.email = e.payload;
        },
        setEmailRepeat(state, e) {
            state.emailRepeat = e.payload;
        },
        setPhoneNumber(state, e) {
            state.phoneNumber = e.payload;
        },
        setPassword(state, c) {
            state.password = c.payload;
        },
        setReserveErrors(state, e){
            state.errors = e.payload
        },
        addReserveError(state, e){
            const error = e.payload;
            const index = state.errors.findIndex(e => e.id == error.id);
            const errorAlreadyExists = index > -1;
            if(!errorAlreadyExists){
                state.errors.push(error);
            }
        },
        removeReserveErrorById(state, i){

            const id = i.payload;
            state.errors = state.errors.filter(error => error.id != id);
        }
    }
});

export const {setDefaultOptions, setDate, setReservedUntilDate, setEmail, setPassword, setEmailRepeat, setPhoneNumber, setReserveErrors, addReserveError, removeReserveErrorById} = tpReserveSlice.actions;

export default tpReserveSlice.reducer;