import React, { useRef } from 'react';
import { isMobile } from '../../App';

function Headline(props) {

    const headlineRef = useRef(null);

    var cssClass = "headline";
    if(props.cssClass != null) {
        cssClass = props.cssClass;
    }

    const mobileSlideAnimationOnScroll = (ev) => {
        if (!isMobile) return;
        var current = headlineRef.current;
        if (!current) return;
        var box = current.getBoundingClientRect();
        if (box.top < 100) {
            if (!current.classList.contains("moveRight")) {
                current.classList.add("moveRight");
            }
        } else {
            current.classList.remove("moveRight");
        }
    };
    window.addEventListener("scroll", mobileSlideAnimationOnScroll);

    return <p ref={headlineRef} id={props.id} className={`${cssClass} ` + ((props.classList !== undefined) ? `${props.classList}` : "")} style={props.styles}>{props.text}</p>;

}

export default Headline;