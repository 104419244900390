import React from 'react';
import { isMobile } from '../../App';

function Burger(props) {
    const toggleMenu = e => {
        var burgerSymbol = document.getElementById("burgerSymbol");
        var phoneSymbol = document.getElementById("phoneSymbol");
        var navBar = document.getElementById("navbar");
        if (burgerSymbol.classList.contains("clicked")) {
                phoneSymbol.classList.remove("hide");
                phoneSymbol.style.display = null;
            burgerSymbol.classList.remove("clicked");
            navBar.classList.remove("show");
        } else {
            phoneSymbol.classList.add("hide");
            burgerSymbol.classList.add("clicked");
            navBar.classList.add("show");
        }
    }

    const toggleMenuMobile = e => {
        var burgerSymbol = document.getElementById("burgerSymbol");
        var navBar = document.getElementById("navbar");
        if (burgerSymbol.classList.contains("clicked")) {
            burgerSymbol.classList.remove("clicked");
            navBar.classList.remove("show");
        } else {
            burgerSymbol.classList.add("clicked");
            navBar.classList.add("show");
        }
    }

    const toggleMenuSubPage = e => {
        var burgerSymbol = document.getElementById("burgerSymbol");
        var navBar = document.getElementById("navbar");
        if (burgerSymbol.classList.contains("clicked")) {
            burgerSymbol.classList.remove("clicked");
            navBar.classList.remove("show");
        } else {
            burgerSymbol.classList.add("clicked");
            navBar.classList.add("show");
        }
    }
    
    return (
        <div className="burger" id="burgerSymbol" onClick={(props.subpage !== undefined) ? toggleMenuSubPage : ((isMobile) ? toggleMenuMobile : toggleMenu)}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );

}

export default Burger;