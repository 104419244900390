import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Container, Image as Img } from "react-bootstrap";
import bg from "../assets/img/Backgrounds/Hintergrund_3.png";

import signs from "../assets/img/explenation/Straßenschilder.png";
import middle_image from "../assets/img/explenation/Ablauf_Mitte.png";
import middle_image_middle from "../assets/img/explenation/Ablauf_Mitte_mittel.png";
import middle_image_small from "../assets/img/explenation/Ablauf_Mitte_ganz_klein.png";
import gps1 from "../assets/img/explenation/GPS_1.png";
import gps2 from "../assets/img/explenation/GPS_2.png";
import gps3 from "../assets/img/explenation/GPS_3.png";
import gps4a from "../assets/img/explenation/GPS_4a.png";
import gps4b from "../assets/img/explenation/GPS_4b.png";
import gps5 from "../assets/img/explenation/GPS_5.png";
import gps6 from "../assets/img/explenation/GPS_6.png";
import gps7 from "../assets/img/explenation/GPS_7.png";
import gps8 from "../assets/img/explenation/GPS_8.png";
import plan_tour from "../assets/img/explenation/Bild_ 1_Tour_planen.png";
import book_tour from "../assets/img/explenation/Bild_2_Buchen.png";
import qualitycheck from "../assets/img/explenation/Bild_ 3_Qualitätscheck.png";
import instruct from "../assets/img/explenation/Bild_5_Einteilen_und_Einweisen.png";
import appDownload from "../assets/img/explenation/Bild_6_App herunterladen.png";
import scan from "../assets/img/explenation/Bild_7_QR-Code_scannen.png";
import play from "../assets/img/explenation/Bild_ 8_Spielen.png";
import overlayLeft from "../assets/img/explenation/GPS_4a.png"; // Add your left image
import overlayLeft_res from "../assets/img/explenation/GPS_4a_Responsive.png";
import overlayRight from "../assets/img/explenation/GPS_4b.png"; // Add your right image
import overlayRight_res from "../assets/img/explenation/GPS_4b_Responsive.png";
import transition from "../assets/img/explenation/uebergang.png";
import { useScreenWidth } from "./../ScreenWidthContext";
import downloadGoole from "../assets/img/explenation/Play Store_Button.png";
import downloadApple from "../assets/img/explenation/App Store_Button.png";

const EXAMPLE_PDF_PATH = process.env.PUBLIC_URL + '/downloads/Genesis_Aventures_Beispielmaterial.pdf';
const HANDOUT_PDF_PATH = process.env.PUBLIC_URL + '/downloads/Genesis_Adventures_Handout.pdf';

const GooglePlayStoreLink = "https://play.google.com/store/apps/details?id=com.espoto.espoto2";
const AppStoreLink = "https://apps.apple.com/de/app/espoto-serious-games/id1619117284";

const Explenation = () => {
  const screenWidth = useScreenWidth();
  const { t } = useTranslation(["explenation"]);

  const generateCurvedRoad = () => {
    const svg = document.getElementById("curvedRoadSvg");
    const container = document.getElementById("explanation-container");
    const topSections = container.querySelectorAll(".top-section");
    const middleSection = document.getElementById("middle-section");

    let totalHeight = 0;
    let pathD = "";
    const width = Math.min(container.offsetWidth, 1920);
    const curveRadius = 150;
    const sideOffset = 250;

    // Start path from first section
    const firstSectionHeight = topSections[0].offsetHeight || 0;
    pathD = `M ${sideOffset / 2 - 25}, ${firstSectionHeight / 2 + 0}`;

    // Generate top sections path
    topSections.forEach((section, index) => {
      const sectionHeight = section.offsetHeight + 0;

      if (index === 0) {
        pathD += ` c 0,${curveRadius} ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
      }
      if (index === topSections.length - 1) {
        pathD += ` h ${width / 2 - sideOffset - curveRadius}`;
        pathD += ` c ${curveRadius},0 ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
      } else if (index % 2 === 0) {
        pathD += ` h ${width - 2 * sideOffset}`;
        if (index < topSections.length - 1) {
          pathD += ` c ${curveRadius},0 ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
          pathD += ` v ${sectionHeight - 2 * curveRadius}`;
          pathD += ` c 0,${curveRadius} -${curveRadius},${curveRadius} -${curveRadius},${curveRadius}`;
        }
      } else {
        pathD += ` h -${width - 2 * sideOffset + 0}`;
        if (index < topSections.length - 1) {
          pathD += ` c -${curveRadius},0 -${curveRadius},${curveRadius} -${curveRadius},${curveRadius}`;
          pathD += ` v ${sectionHeight - 2 * curveRadius}`;
          pathD += ` c 0,${curveRadius} ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
        }
      }
      totalHeight += sectionHeight;
    });

    // End first path at middle section
    pathD += ` M ${width / 2 + curveRadius - curveRadius}, ${
      totalHeight + middleSection.offsetHeight + curveRadius
    }`;

    // Generate bottom sections path
    const bottomSections = container.querySelectorAll(".bottom-section");

    bottomSections.forEach((section, index) => {
      const sectionHeight = section.offsetHeight + 0;

      // console.log("index hier", index);

      //wenn erstes
      if (index === 0) {
        pathD += ` c 0,${curveRadius} ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
      }
      //wenn letztes
      if (index === bottomSections.length - 2) {
        pathD += ` h ${width - 2 * sideOffset}`;
        pathD += ` c ${curveRadius},0 ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
        pathD += ` v ${sectionHeight - 2 * curveRadius}`;
        pathD += ` c 0,${curveRadius} -${curveRadius},${curveRadius} -${curveRadius},${curveRadius}`;
        pathD += ` h -${width / 2 - sideOffset}`;
        pathD += ` c -${curveRadius},0 -${curveRadius},${curveRadius} -${curveRadius},${curveRadius}`;
        pathD += ` c 0,${curveRadius} ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
        pathD += ` h ${width / 2 - 2.5 * sideOffset}`;
      }
      //alle geraden kurven
      else if (index % 2 === 0) {
        if (index !== bottomSections.length - 1) {
          pathD +=
            index === 0
              ? ` h ${width / 2 - 2 * curveRadius - (170 / 2 + 20)}`
              : ` h ${width - 2 * sideOffset}`;
        }
        if (index < bottomSections.length - 1) {
          pathD += ` c ${curveRadius},0 ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
          pathD += ` v ${sectionHeight - 2 * curveRadius}`;
          pathD += ` c 0,${curveRadius} -${curveRadius},${curveRadius} -${curveRadius},${curveRadius}`;
        }
      }
      //alle ungeraden kurven
      else {
        if (index !== bottomSections.length - 1) {
          pathD += ` h -${width - 2 * sideOffset}`;
        }
        if (index < bottomSections.length - 1) {
          pathD += ` c -${curveRadius},0 -${curveRadius},${curveRadius} -${curveRadius},${curveRadius}`;
          pathD += ` v ${sectionHeight - 2 * curveRadius}`;
          pathD += ` c 0,${curveRadius} ${curveRadius},${curveRadius} ${curveRadius},${curveRadius}`;
        }
      }
      totalHeight += sectionHeight;
    });

    document.getElementById("curvedRoadLine").setAttribute("d", pathD);
    svg.style.height = `${container.offsetHeight}px`;
    //container.style.height = `${allSectionHeight}px`;
  };

  const generateStraightRoad = () => {
    const svg = document.getElementById("straightRoadSvg");
    const container = document.getElementById("explanation-container");
    const startElement = document.getElementById("gps1");
    const middleSection = document.getElementById("middle-section");
    const endElement = document.getElementById("lastImage");

    if (!startElement || !endElement || !svg || !container || !middleSection) {
      return;
    }

    // Get the container's bounding rect for relative positioning
    const containerRect = container.getBoundingClientRect();

    // Get the positions relative to the container
    const startRect = startElement.getBoundingClientRect();
    const middleRect = middleSection.getBoundingClientRect();
    const endRect = endElement.getBoundingClientRect();

    // Calculate positions relative to the container
    const startY = startRect.top - containerRect.top + startRect.height / 2;
    const middleTopY = middleRect.top - containerRect.top;
    const middleBottomY = middleRect.bottom - containerRect.top;
    const endY = endRect.top - containerRect.top + endRect.height / 2;

    // Calculate the center X position
    const centerX = container.offsetWidth / 2;

    // Create two paths with a gap in the middle
    const pathD = `M ${centerX}, ${startY} L ${centerX}, ${middleTopY} M ${centerX}, ${middleBottomY} L ${centerX}, ${endY}`;

    // Update SVG elements
    document.getElementById("straightRoadLine").setAttribute("d", pathD);
    svg.style.height = `${container.offsetHeight}px`;
  };

  const generateRoadPath = () => {
    generateStraightRoad();
    generateCurvedRoad();
  };

  const checkVisibility = () => {
    const blocks = document.querySelectorAll(".content-block");
    blocks.forEach((block) => {
      const rect = block.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight - 100;
      if (isVisible) {
        block.classList.add("visible");
      }
    });
  };

  useEffect(() => {
    generateRoadPath();
    checkVisibility();

    window.addEventListener("resize", generateRoadPath);
    window.addEventListener("scroll", checkVisibility);

    const observer = new ResizeObserver(() => {
      generateRoadPath();
    });

    document.querySelectorAll(".content-section").forEach((section) => {
      observer.observe(section);
    });

    // Cleanup
    return () => {
      window.removeEventListener("resize", generateRoadPath);
      window.removeEventListener("scroll", checkVisibility);
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Container fluid id="a-howto" className="position-relative ps-0 pe-0">
        <div className="explenation-backgrounds">
          <Img src={bg} className="bg" />
        </div>

        <div className="explanation-content-space">
          <div className="contact-headline">
            {t("project_genesis.explenation.headline")}
          </div>
          <div id="explanation-container" className="explanation-container">
            <div className="road-container">
              <svg
                width="100%"
                height="100%"
                preserveAspectRatio="none"
                id="curvedRoadSvg"
              >
                <path className="road-line" id="curvedRoadLine" d="" />
              </svg>

              <svg
                width="100%"
                height="100%"
                preserveAspectRatio="none"
                id="straightRoadSvg"
              >
                <path className="road-line" id="straightRoadLine" d="" />
              </svg>
            </div>

            <div key={0} id="plan-tour" className="content-section top-section">
              <img
                src={gps1}
                className="curved-road-gps-icon start-icon"
                alt="GPS Icon"
              />
              <div className="content-wrapper">
                <div className="info-container">
                  <div className="row">
                    <div className="col side-col">
                      <div className="explanationStep text-align-left">
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <span className="explenation-title">
                                  TOUR PLANEN
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <span className="explenation-description">
                                  Planen Sie Ihre Stadtrallye ganz einfach mit
                                  unserem Konfigurator.
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <Button
                                  className="explenation-button"
                                  onClick={() =>
                                    (window.location.href = "#a-tourplanner")
                                  }
                                >
                                  <i className="bi bi-gear button-icon"></i>
                                  ZUM KONFIGURATOR
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col straight-road-middle-col">
                      <img
                        src={gps1}
                        id="gps1"
                        className="straight-road-gps-icon start-icon"
                        alt="GPS Icon"
                      />
                    </div>
                    <div className="col side-col">
                      <img
                        className="rounded-image rounded-image-right"
                        src={plan_tour}
                        alt="Plan Tour"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div key={1} id="book" className="content-section top-section">
              <img
                src={gps2}
                className="curved-road-gps-icon gps-icon-right"
                alt="GPS Icon"
              />
              <div className="content-wrapper">
                <div className="info-container">
                  <div className="row">
                    <div className="col side-col">
                      <img
                        className="rounded-image rounded-image-left"
                        src={book_tour}
                        alt="Book Tour"
                      />
                    </div>
                    <div className="col straight-road-middle-col">
                      <img
                        src={gps2}
                        className="straight-road-gps-icon"
                        alt="GPS Icon"
                      />
                    </div>
                    <div className="col side-col">
                      <div className="explanationStep text-align-right">
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <span className="explenation-title">
                                  BUCHEN
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <span className="explenation-description">
                                  Nachdem Sie Ihre erstellte Tour gebucht haben,
                                  erhalten Sie eine Bestätigungsmail.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              key={2}
              id="quality-check"
              className="content-section top-section"
            >
              <img
                src={gps3}
                className="curved-road-gps-icon gps-icon-left"
                alt="GPS Icon"
              />
              <div className="content-wrapper">
                <div className="info-container">
                  <div className="row flex-nowrap">
                    <div className="col side-col">
                      <div className="explanationStep text-align-left">
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <span className="explenation-title">
                                  QUALITÄTSCHECK
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <span className="explenation-description">
                                  Um den Laufweg zu prüfen und das Spielmaterial
                                  zu finalisieren, nehmen wir uns bis zu 24 Stunden
                                  Zeit. Anschließend erhalten Sie eine Mail mit
                                  den Spielzugängen und Ihrem Spielmaterial.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col straight-road-middle-col">
                      <img
                        src={gps3}
                        className="straight-road-gps-icon"
                        alt="GPS Icon"
                      />
                    </div>
                    <div className="col side-col">
                      <img
                        className="rounded-image rounded-image-right"
                        src={qualitycheck}
                        alt="App Download"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="middle-section">
              <img src={signs} className="signs-image"></img>
              <div className="price-overlay-container left">
                <img
                  src={screenWidth > 1100 ? overlayLeft : overlayLeft_res}
                  className="middle-overlay-left"
                  alt="Left overlay"
                />
                <div className="price-text digital-price">
                  <div className="euro-line">€</div>
                  <div className="price">14,90€</div>
                  <div className="price-description">PRO SCHÜLER</div>
                </div>
              </div>
              <div className="price-overlay-container right">
                <img
                  src={screenWidth > 1100 ? overlayRight : overlayRight_res}
                  className="middle-overlay-right"
                  alt="Right overlay"
                />
                <div className="price-text physical-price">
                  <div className="euro-line">€€</div>
                  <div className="price">18,90€</div>
                  <div className="price-description">PRO SCHÜLER</div>
                </div>
              </div>
              <img
                src={
                  screenWidth < 800
                    ? screenWidth <= 600
                      ? middle_image_small
                      : middle_image_middle
                    : middle_image
                }
              />
              <div className="middle-section-top">
                <div className="row" style={ screenWidth <= 1100 ? {textAlign: "right"} : {}}>
                  <div className="col">
                    <div className="row">
                      <div className="col title">MATERIAL SELBST VORBEREITEN</div>
                    </div>
                    <div className="row">
                      <div className="col description">
                        Haben Sie im Tourkonfigurator die PDF-Variante gebucht,
                        können Sie nun beginnen Ihr Spielmaterial auszudrucken
                        und vorzubereiten.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Button 
                          className="explenation-button middle-top-button"
                          onClick={() => window.open(EXAMPLE_PDF_PATH, '_blank')}
                        >
                          <i className="bi bi-download button-icon"></i>
                          BEISPIELMATERIAL
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="middle-section-bottom">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col title" style={ screenWidth <= 1100 ? {textAlign: "left"} : {}}>POSTBOTE ABWARTEN</div>
                    </div>
                    <div className="row">
                      <div className="col description" style={ screenWidth <= 1100 ? {textAlign: "left"} : {}}>
                        Haben Sie die Versand-Variante gewählt, erhalten Sie
                        binnen acht Tagen nach dem Qualitätscheck das
                        Spielmaterial fertig zusammengestellt per Post.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              key={4}
              id="organize-and-instruct"
              className="content-section bottom-section"
            >
              <img
                src={gps5}
                className="curved-road-gps-icon gps-icon-right"
                alt="GPS Icon"
              />
              <div className="content-wrapper full-width-section">
                <div className="info-container">
                  <div className="row">
                    <div className="col side-col">
                      <img
                        className="rounded-image rounded-image-left"
                        src={instruct}
                        alt="Instruct"
                      />
                    </div>
                    <div className="col straight-road-middle-col">
                      <img
                        src={gps5}
                        className="straight-road-gps-icon"
                        alt="GPS Icon"
                      />
                    </div>
                    <div className="col side-col">
                      <div className="explanationStep text-align-right">
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <span className="explenation-title">
                                  EINTEILEN & EINWEISEN
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <span className="explenation-description">
                                  Die Schüler werden nun in Kleingruppen von 4 -
                                  5 Schülern aufgeteilt. Für die Einweisung
                                  Ihrer Schüler ins Spiel, können Sie das Video
                                  oder das Handout verwenden.
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                {/* <Button className="explenation-button no-break w-full">
                                  <i className="bi bi-play button-icon"></i>
                                  VIDEO
                                </Button> */}
                              </div>
                              <div className="col">
                                <Button className="explenation-button no-break w-full" onClick={() => window.open(HANDOUT_PDF_PATH, '_blank')}>
                                  <i className="bi bi-download button-icon"></i>
                                  HANDOUT
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              key={5}
              id="download-app"
              className="content-section bottom-section"
            >
              <img
                src={gps6}
                className="curved-road-gps-icon gps-icon-left"
                alt="GPS Icon"
              />
              <div className="content-wrapper download-content-wrapper">
                <div className="info-container">
                  <div className="row">
                    <div className="col side-col">
                      <div className="explanationStep text-align-left">
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <span className="explenation-title">
                                  APP HERUNTERLADEN
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <span className="explenation-description">
                                  Der jeweilige Kleingruppenleiter lädt nun die kostenlose
                                  Spiel-App „espoto serious games“ auf sein
                                  Handy herunter. Darüber werden die
                                  Schüler durch die Tour geleitet.
                                </span>
                              </div>
                            </div>
                            { screenWidth > 1280 ?
                            <div className="col" style={{marginTop: 16}}>
                              <a href={GooglePlayStoreLink} style={{marginRight: 40}} target="_blank"><img src={downloadGoole} width="25%"/></a>
                              <a href={AppStoreLink} target="_blank"><img src={downloadApple} width="25%"/></a>
                            </div>
                            :
                            <div className="col" style={{marginTop: 16}}>
                              <a href={GooglePlayStoreLink} style={{marginRight: 20}} target="_blank"><img src={downloadGoole} width="35%"/></a>
                              <a href={AppStoreLink} target="_blank"><img src={downloadApple} width="35%"/></a>
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col straight-road-middle-col">
                      <img
                        src={gps6}
                        className="straight-road-gps-icon"
                        alt="GPS Icon"
                      />
                    </div>
                    <div className="col side-col">
                      <img
                        className="rounded-image rounded-image-right"
                        src={appDownload}
                        alt="Scan Code"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              key={6}
              id="qr-code"
              className="content-section bottom-section"
            >
              <img
                src={gps7}
                className="curved-road-gps-icon gps-icon-right"
                alt="GPS Icon"
              />
              <div className="content-wrapper qr-content-wrapper ">
                <div className="info-container">
                  <div className="row flex-nowrap">
                    <div className="col side-col">
                      <img
                        className="rounded-image rounded-image-left"
                        src={scan}
                        alt="App Download"
                      />
                    </div>
                    <div className="col straight-road-middle-col">
                      <img
                        src={gps7}
                        className="straight-road-gps-icon"
                        alt="GPS Icon"
                      />
                    </div>
                    <div className="col side-col">
                      <div className="explanationStep text-align-right">
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <span className="explenation-title">
                                  QR-CODE SCANNEN
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <span className="explenation-description">
                                  Alle Kleingruppenleiter scannen nun den
                                  Spielzugang aus Ihrer Mail und die Tour
                                  beginnt. Ungenutzte QR-Codes bleiben 12 Monate
                                  gültig.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div key={7} id="play" className="content-section bottom-section">
              <img
                src={gps8}
                className="curved-road-gps-icon"
                alt="GPS Icon"
                style={{left: "unset", transform: "translateX(-152px)"}}
              />
              <div className="content-wrapper player-content-wrapper">
                <div className="straight-road-middle-col" style={{marginBottom: "32px"}}>
                  <img
                    src={gps8}
                    className="straight-road-gps-icon"
                    alt="GPS Icon"
                  />
                </div>
                <div className="end-info-container" style={{width: "55%"}}>
                  <div className="row flex-nowrap">
                    <div className="col">
                      <img
                        className="rounded-image end-rounded-image"
                        id="lastImage"
                        src={play}
                        alt="App Download"
                      />
                    </div>
                  </div>
                  <div className="row flex-nowrap">
                    <div className="col">
                      <div className="explenation-title text-center">
                        SPIELEN, RÄTSELN & SPAß HABEN!
                      </div>
                      <div className="explenation-description">
                        Jetzt gilt es alle Wegpunkte zu finden und die Rätsel zu lösen. Welche Gruppe wird die schnellste und erfolgreichste sein?
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={transition} className="transition-image" />
      </Container>
    </>
  );
};

export default Explenation;
