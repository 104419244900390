// context/ScreenWidthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// Initialisiere den Context
const ScreenWidthContext = createContext();

// Der Provider, der den Bildschirmwert bereitstellt
export const ScreenWidthProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Füge einen Resize Event Listener hinzu, um die Bildschirmbreite zu aktualisieren
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Entferne den Event Listener, wenn der Component unmontiert wird
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ScreenWidthContext.Provider value={screenWidth}>
      {children}
    </ScreenWidthContext.Provider>
  );
};

// Hook, um auf die Bildschirmbreite zuzugreifen
export const useScreenWidth = () => {
  const context = useContext(ScreenWidthContext);
  if (context === undefined) {
    throw new Error('useScreenWidth must be used within a ScreenWidthProvider');
  }
  return context;
};