import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { isMobile } from '../App';
import { hidemenu, hidemenusub } from './WelcomeSection';

function MenuBar(props) {

  const { t } = useTranslation(['menu']);

  const phoneClick = (ev) => {
    document.location.href = "tel:" + t('project_genesis.menubar.phone_number');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'phoneButtonClicked',
    });
  };

  const emailClick = (ev) => {
    window.location.href = `mailto:${t('project_genesis.menubar.email')}`;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'emailButtonClicked',
    });
  };

  const hideMenu = (e) => {
    hidemenu(e, true);
  }

  var links = (
    <div className='spread-evenly-vertically'>
      <Nav.Link className="link" href='#a-welcome' onClick={hideMenu}>{t('project_genesis.menubar.menusections.welcome')}</Nav.Link>
      <Nav.Link className="link" href='#a-tourplanner' onClick={hideMenu}>{t('project_genesis.menubar.menusections.plan_tour')}</Nav.Link>
      <Nav.Link className="link" href='#a-howto' onClick={hideMenu}>{t('project_genesis.menubar.menusections.howto')}</Nav.Link>
      <Nav.Link className="link" href='#a-contact' onClick={hideMenu}>{t('project_genesis.menubar.menusections.contact')}</Nav.Link>
      <Nav.Link className="link" href='#a-faq' onClick={hideMenu}>{t('project_genesis.menubar.menusections.faq')}</Nav.Link>
      <Nav.Link className="link" href='#a-explore' onClick={hideMenu}>{t('project_genesis.menubar.menusections.whatis')}</Nav.Link>
      <Nav.Link className="link" href='#a-about' onClick={hideMenu}>{t('project_genesis.menubar.menusections.about')}</Nav.Link>
      {/* <Nav.Link className="link" href='#a-adventure-showcase' onClick={hideMenu}>{t('project_genesis.menubar.menusections.adventure_showcase')}</Nav.Link> */}
    </div>
  );

  // Different links for subpages like the blogpage
  if (props.subpage !== undefined) {
    links = (
        <div className='spread-evenly-vertically'>
          <Nav.Link className="link" href='/#a-welcome' onClick={hideMenu}>{t('project_genesis.menubar.menusections.welcome')}</Nav.Link>
          <Nav.Link className="link" href='/#a-tourplanner' onClick={hideMenu}>{t('project_genesis.menubar.menusections.plan_tour')}</Nav.Link>
          <Nav.Link className="link" href='/#a-howto' onClick={hideMenu}>{t('project_genesis.menubar.menusections.howto')}</Nav.Link>
          <Nav.Link className="link" href='/#a-contact' onClick={hideMenu}>{t('project_genesis.menubar.menusections.contact')}</Nav.Link>
          <Nav.Link className="link" href='/#a-faq' onClick={hideMenu}>{t('project_genesis.menubar.menusections.faq')}</Nav.Link>
          <Nav.Link className="link" href='/#a-explore' onClick={hideMenu}>{t('project_genesis.menubar.menusections.whatis')}</Nav.Link>
          <Nav.Link className="link" href='/#a-about' onClick={hideMenu}>{t('project_genesis.menubar.menusections.about')}</Nav.Link>
          {/* <Nav.Link className="link" href='#a-adventure-showcase' onClick={hideMenu}>{t('project_genesis.menubar.menusections.adventure_showcase')}</Nav.Link> */}
        </div>
    );
    window.addEventListener("click", hidemenusub);
  }

  return(
    <Container fluid>
      <Navbar className='sticky-top sidebar navbar' id="navbar">
        <Nav className='nav-flex'>
            {links}
            <span className='line'></span>
            <p>{t('project_genesis.menubar.menusections.note')}</p>
            <div className='footer'>
              <span className='phonesymbol' onClick={phoneClick}>{!isMobile && <p className='onhover'>{t('project_genesis.menubar.phone_number')}</p>}</span>
              <span className='emailsymbol' onClick={emailClick}></span>
            </div>
        </Nav>
      </Navbar>
    </Container>
  );
}

export default MenuBar;